import { connectEndpoint } from "./components/config";

const INCOMING_MSG_AUDIO_NOTIFICATION_FILE_PATH =
  "/resources/audio/amz_agent_notification.mp3";
const AUDIO: HTMLAudioElement = new Audio(
  new URL(
    INCOMING_MSG_AUDIO_NOTIFICATION_FILE_PATH,
    connectEndpoint()
  ).toString()
);

/**
 * This function plays a sound when a message is received from the customer
 *
 * @param event connect.ChatMessageEvent
 */
export function playSound(event: connect.ChatMessageEvent): void {
  if (isCustomerMessage(event)) {
    // play a sound
    void AUDIO.play();
  }
}

// Helper to determine if the message is a system message
function isCustomerMessage(event: connect.ChatMessageEvent): boolean {
  const { Type, ParticipantRole } = event.data;
  if (Type === "MESSAGE" && ParticipantRole === "CUSTOMER") {
    return true;
  }
  return false;
}
