export const ITS_MESSAGE_EXTERNAL_SESSION_STRINGS: Record<string, string> = {
  eng: "Please use the link to download the remote client and start a session",
  spa:
    "Utilice el enlace para descargar el cliente remoto e iniciar una sesión.",
  deu:
    "Bitte benutzen Sie den Link, um den Remote-Client herunterzuladen und eine Sitzung zu starten",
  por: "Use o link para baixar o cliente remoto e iniciar uma sessão",
  fra:
    "Veuillez utiliser le lien pour télécharger le client distant et démarrer une session",
  ita:
    "Utilizzare il collegamento per scaricare il client remoto e avviare una sessione",
  jpn:
    "リンクを使用してリモート クライアントをダウンロードし、セッションを開始してください。",
  kor: "링크를 사용하여 원격 클라이언트를 다운로드하고 세션을 시작하세요.",
};
